body {
  -webkit-user-select: none; /* Dành cho Safari */
  -moz-user-select: none; /* Dành cho Firefox */
  -ms-user-select: none; /* Dành cho Internet Explorer */
  user-select: none; /* Chuẩn CSS */
}
#HomePage {
  height: 100%;
  flex: 1;
  overflow: auto;
}

.context-menu-dropdown * {
  margin: 0;
  padding: 0;
  transition: all 0.3s;
}

.context-menu-dropdown #mainnav {
  box-shadow: rgba(0, 0, 0, 0.133) 0 3.2px 7.2px 0,
    rgba(0, 0, 0, 0.11) 0 0.6px 1.8px 0;
  background: #fff;
}

.context-menu-dropdown #mainnav ul {
  list-style: none;
  padding: 0;
  width: 200px;
  text-align: left;
}

.context-menu-dropdown #mainnav ul li {
  position: relative;
  line-height: 36px;
}

.context-menu-dropdown #mainnav ul li a {
  text-decoration: none;
  padding: 0 8px;
  display: block;
  color: #222;
}

.context-menu-dropdown #mainnav li:hover {
  background: #eee;
}

.ms-ContextualMenu-container .line:hover {
  background: #f3f2f1;
}

.node {
  line-height: 30px;
}

.child.selected {
  background-color: #b2b2b2 !important;
}

.child {
  color: #333 !important;
  background: #fff;
  cursor: pointer;
  padding: 0 30px;
}

.child:hover {
  background: #eee;
}

.parent .label {
  color: #008eff;
  background: #f3f2f1;
  padding: 0 10px;
}

.treeWrapper svg {
  width: 25px;
}

.ms-Modal-Layer[data-portal-element="true"] {
  position: absolute;
  height: 100%;
}

.ms-Modal-Layer .ms-Modal.is-open {
  background: rgb(4 4 4 / 30%);
}

.ms-Dialog .ms-Dialog-main {
  max-width: 600px;
}

.ms-Tooltip-btn-unity {
  padding: 4px;
}

.ms-Modal-scrollableContent {
  overflow-y: hidden;
  max-height: fit-content;
}

.rectangle {
  border: 1px solid #ff0000;
  position: absolute;
}

.header-left-panel {
  line-height: 40px;
  border-bottom: 1px solid #b2b2b2;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 13px;
}

#viewerContainerDocuVieware_Main {
  background-color: #bbbbbb !important;
}

.mg-top15 {
  margin-top: 10px;
}

.searching-template {
  height: 250px;
  overflow: auto;
}
.accuracyValue input {
  min-width: 36px;
}
.accuracyValue {
  min-width: 60px;
}
.splitter-layout .layout-pane {
  overflow: hidden !important;
}
.splitter-layout > .layout-splitter {
  width: 4px !important;
}
.react-split__sash.react-split__sash--vertical {
  background-color: #b2b2b2 !important;
}
.dzu-dropzone {
  width: 100% !important;
  height: calc(100% - 82px) !important;
}
.colword {
  padding: 0px;
}
.ms-ContextualMenu-header {
  color: #333;
  background: #e9e9e9;
}
.templateSearchList .ms-DetailsRow-cell {
  padding: 0px !important;
}
.autoFlowSetting .header {
  background: #f3f2f1;
  padding: 5px;
  border: #e9e9e9 1px solid;
  height: 34px;
  box-sizing: border-box;
  font-size: 11px;
  line-height: 20px;
}
.autoFlowSetting .container {
  padding: 15px;
  border: #e9e9e9 1px solid;
  box-sizing: border-box;
  height: 100%;
}
.autoFlowSetting .ms-Pivot-link {
  height: 30px;
  box-sizing: border-box;
}
.autoFlowSetting .ms-Pivot {
  background: #f3f2f1;
}
.autoFlowSetting [role="tabpanel"] {
  height: 450px;
}
.tagtab .ms-DetailsRow-cell {
  width: 105px !important;
}
.tagtab .ms-DetailsRow-cell:nth-child(4) {
  width: 40px !important;
}
.autoFlowSetting .autoFlowlist {
  height: calc(100% - 53.5px);
  overflow: auto;
  border-left: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.ms-DetailsRow-check {
  height: 30px;
}
.autoFlowSetting .ms-DetailsRow-cell:has(> div.active) {
  background: #f3f2f1;
}
.rct-tree-items-container .col {
  padding: 0 4px 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-row {
  display: flex;
  align-items: center;
  height: 30px;
  border-top: 1px solid #b2b2b2;
}
.header-row .col-header {
  border-left: 1px solid #b2b2b2;
  padding: 0 4px 0 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
.header-row .col-header:last-child {
  border-right: 1px solid #b2b2b2;
}
.header-row .col {
  padding: 0 4px 0 10px;
}
.rct-tree-items-container .row {
  display: flex;
  height: 30px;
  align-items: center;
  width: 100% !important;
  box-sizing: border-box;
  box-shadow: 1px 1px #b2b2b2;
}
.rct-tree-items-container div :has(.rct-tree-item-arrow) {
  width: 0;
}
.rct-tree-item-arrow {
  margin-left: 3px;
}
/* .rct-tree-items-container li {
  border-top: 1px solid #b2b2b2;
} */

.rct-tree-items-container ul[role="group"] {
  padding-left: 10px;
}
.replaceTemplate-list div[data-rct-tree="tree-2"] {
  border-bottom: 1px solid #b2b2b2;
}
.react-split__sash--horizontal {
  background-color: #b2b2b2 !important;
}
#fluent-default-layer-host {
  z-index: unset !important;
  position: relative !important;
}

.rct-tree-items-container li[aria-selected="true"] > div:first-child {
  background-color: #a8d3f3;
}
.react-checkbox-tree label {
  display: flex;
  align-content: end;
}
.cp_tree-table {
  font-size: 11px;
}
.cp_tree-table_row:hover {
  background-color: #f3f2f1;
}
.cp_tree-table .cp_tree-table_cell .toggle-button {
  border: solid #333333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  width: 7px;
  height: 7px;
  margin-left: 2px;
  margin: 3px 5px 2px 2px;
  box-sizing: border-box;
  cursor: pointer;
}
.cp_tree-table_cell {
  height: 100%;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #b2b2b2;
  border-left: 1px solid #b2b2b2;
}
.cp_tree-table_header-cell {
  padding: 0 10px;
}
.cp_tree-table_cell .ms-TextField-fieldGroup {
  border: none;
}
.without-children,
.with-children {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  width: 100%;
  align-content: center;
}
.with-children i {
  padding: 5px;
  cursor: pointer;
}
.cp_tree-table_row {
  align-items: center;
  border-top: 1px solid #edebe9;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}

.rct-tree-item-arrow svg {
  width: 10px !important;
  height: 10px !important;
}
.rct-tree-item-arrow {
  height: 10px !important;
}

.combo-tree {
  position: relative;
}

.combo-input {
  border: 1px solid #605e5c;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  height: 31px;
  box-sizing: border-box;
}
.combo-input input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 11px;
}

.dropdown-icon {
  margin-left: 10px;
  color: #888;
}

/* Dropdown tree container */
.dropdown-tree {
  position: absolute;
  top: calc(100% + 5px); /* Hiển thị ngay bên dưới input */
  left: 0;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-height: 200px; /* Giới hạn chiều cao */
  overflow-y: auto; /* Thêm scrollbar nếu vượt quá chiều cao */
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.toc-table-tree .with-children {
  display: flex;
  align-items: center;
}
.toc-table-setting td {
  height: 35px;
}
.ms-Dialog-main {
  min-height: 100px;
}
.resizer:hover {
  background: #b2b2b2;
}
.resizer {
  background: transparent;
  width: 2px;
  cursor: col-resize;
  height: 100%;
  right: 0;
  position: absolute;
}
.resizer.left {
  left: 0;
}
.ms-ContextualMenu-list li[role="separator"] {
  height: 0;
}
.SearchTemplate .react-split__sash--horizontal{
  margin-top: 5px;
}