html,
body {
  min-width: 1280px;
  min-height: 500px;
  height: 100vh;
}

ul {
  list-style: none;
}

body,
#root,
.modal-content,
.h100 {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #626262;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
.h100 {
  height: 100% !important;
}
#root {
  position: relative;
}
/* body .ms-Layer.ms-Layer--fixed {
  z-index: 1000000;
} */

body .contextual-menu {
  position: absolute;
  z-index: 10;
}

body .ms-Dialog .ms-Overlay {
  background-color: rgb(0 0 0 / 33%);
}

.react-draggable {
  background: #fff;
  position: absolute;
  width: 250px;
  z-index: 100000;
  box-shadow: 1px 1px 5px 1px #201f1e;
}

.tag-form .header {
  width: 100%;
  display: block;
  line-height: 37px;
  padding: 0 10px;
  border-bottom: 1px solid #edebe9;
  box-sizing: border-box;
  cursor: move;
}

.ms-GroupHeader-title,
.ms-Button,
.ms-Checkbox-text,
.ms-ChoiceFieldLabel,
.ms-Pivot-text,
.ms-ContextualMenu-itemText,
.ms-DetailsHeader-cellName,
.rct-title,
.ms-Label,
.ms-TextField,
.ms-Dropdown-title,
.ms-ColorPicker-table,
label,
.ms-TextField-field,
.ms-DetailsRow-cell {
  font-size: 11px !important;
}

.ms-DetailsHeader {
  padding-top: 0px;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 8px;
}

.loader {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: auto;
  width: 35px;
  height: 35px;
  border-width: 0px;
}
.ms-Spinner-circle {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: auto;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #5495f6;
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  border-color: #abeca3;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #17171763;
  z-index: 10000;
}

.ms-Dialog-main {
  border-radius: 6px;
}

.ms-Button {
  border-radius: 3px;
}

.ms-Callout {
  box-shadow: rgb(0 0 0 / 25%) 0px 6.4px 14.4px 0px,
    rgb(0 0 0 / 18%) 0px 1.2px 3.6px 0px;
}

.ms-List [class*="groupHeaderContainer"] {
  height: 33px;
}

.ms-List .ms-GroupHeader {
  height: 33px;
  background: #f0f0f0;
}

.ms-DetailsList .ms-DetailsHeader-cellTitle,
.ms-List .ms-DetailsHeader-cellTitle {
  padding: 0px 8px 0px 8px;
}

.ms-List .ms-GroupHeader-expand,
.groupHeaderContainer-285 {
  height: 33px;
}

.ms-List .ms-DetailsRow {
  min-height: 33px;
}

.ms-List .ms-Checkbox {
  margin-bottom: 0;
}

.ms-List .ms-DetailsRow-cell {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 33px;
  padding-top: 0;
  padding-bottom: 0;
  /* white-space: break-spaces; */
  box-sizing: border-box;
  line-height: 21px;
  display: flex;
  align-items: center;
}

.ms-List .ms-List-cell {
  min-height: 33px;
}

.ms-List .text {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.ms-DetailsHeader-cell {
  border-left: 1px solid #edebe9;
  border-top: 1px solid #edebe9;
  height: 33px;
}

.ms-DetailsHeader {
  padding-top: 0;
  line-height: 33px;
  height: 33px;
}

.ms-DetailsHeader .ms-DetailsHeader-cell:last-child {
  border-right: 1px solid #edebe9;
}

.ms-DetailsRow-fields .ms-DetailsRow-cell:last-child {
  border-right: 1px solid #edebe9;
}

.ms-ChoiceFieldGroup .ms-ChoiceField:first-child {
  margin-top: 0px;
}

.ms-Checkbox-checkbox,
.ms-ChoiceField-field::before {
  height: 16px;
  width: 16px;
}

.ms-ChoiceFieldGroup,
.ms-ChoiceField-field {
  font-size: 12px !important;
}

.ms-ChoiceField-field::after {
  border-width: 4px;
  top: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  right: 0px;
}

.ms-ChoiceField-field:hover::after {
  content: "";
  transition-property: background-color;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  background-color: rgb(96, 94, 92);
}

.ms-Checkbox-text {
  line-height: 16px;
  white-space: nowrap;
}

.ms-DetailsRow-cell .ms-TextField-fieldGroup {
  border: 1px solid rgb(255 255 255);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.searchtab div[role="tabpanel"] {
  margin-bottom: 44px;
}

.ms-Button {
  padding: 0 8px !important;
}

.ms-Spinner-circle {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: auto;
}

.ms-Spinner-circle::before,
.ms-Spinner-circle::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #5495f6;
  animation: prixClipFix 2s linear infinite;
}

.ms-Spinner-circle::after {
  border-color: #abeca3;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.ms-Overlay {
  background-color: rgba(0, 0, 0, 0.25);
}

.treeToc {
  height: 100%;
}

.treeToc .selected,
.treeToc .selected input {
  background-color: #a8d3f3;
}

.dnd-sortable-tree_simple_clone .row {
  display: flex;
  width: 100%;
}

.treeToc .row {
  display: flex;
  border-left: 1px solid #b2b2b2;
  border-top: 1px solid #b2b2b2;
  height: 30px;
  align-items: center;
}
.treeToc .rct-tree-root {
  border-right: 1px solid #b2b2b2;
}

.treeToc .col {
  border-right: 1px solid #b2b2b2;
  height: 100%;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeToc .border-top {
  border-top: 1px solid #b2b2b2;
}
.treeToc .border-bottom {
  border-bottom: 1px solid #b2b2b2;
}

.treeToc .border-right {
  border-right: 1px solid #b2b2b2;
}

.treeToc .border-left {
  border-left: 1px solid #b2b2b2;
}

.rct-tree-root {
  padding: 0 !important;
}
.rct-tree-root [role="tree"] {
  min-height: 0 !important;
}
.treeToc li[aria-selected="true"] > div:first-child {
  background-color: #a8d3f3;
}

.treeToc ul[role="group"] {
  padding-left: 10px;
}

.tabtoc {
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  line-height: 30px;
}

.rct-tree-item-li-dragging-over {
  background-color: red;
}
.removebtn:hover {
  background-color: rgb(243, 242, 241);
}

.SplitterLayout-duplicate {
  height: calc(100% - 146px) !important;
  width: calc(100% - 50px) !important;
}

.split__left {
  width: 50%;
}

.split__right {
  flex: 1;
}

.split__bar {
  background-color: #000;
  width: 1px;
  height: 100%;
  cursor: col-resize;
}
.container.split {
  display: flex;
  height: 100%;
  width: 100%;
}
.invoiceTabItem {
  padding: 8px 8px;
  border-bottom: 1px #ddd solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected {
  background-color: rgb(168, 211, 243);
}
.table-settings {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding-bottom: 10px;
  margin-top: 10px;
  .table-header {
    border-bottom: 1px solid #b2b2b2;
  }
}
.ms-Label {
  padding-top: 0px;
}
.ms-Grid-row .ms-Grid-col {
  margin-top: 5px;
}
